import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "templateKey": "en-createassistant",
  "title": "test",
  "seo": {
    "title": "-",
    "descr": "-"
  },
  "back": "Back",
  "next": "Next",
  "emailSection": {
    "mascot": "/img/robbie.png",
    "step": 1,
    "title": "Your digital assistant is almost ready",
    "text": "Please enter your e-mail address to receive more information about your digital assistant.",
    "email": "Email"
  },
  "sellingPoints": {
    "title": "Why a digital assistant?",
    "points": [{
      "icon": "/img/time.png",
      "text": "Save time"
    }, {
      "icon": "/img/search.png",
      "text": "Minimize errors"
    }, {
      "icon": "/img/earn.png",
      "text": "Lower cost"
    }, {
      "icon": "/img/creative.png",
      "text": "Focus on more important tasks"
    }]
  },
  "buildAssistant": {
    "title": "Build your digital assistant",
    "text": "Configure a digital assistant to take care of the tasks you want to automate.",
    "mascot": "/img/hello1.png",
    "step": 2
  },
  "areas": {
    "title": "Where do you need a digital assistant?",
    "text": "Common areas that can be automated. Choose one or more departments.",
    "categories": [{
      "icon": "/img/arrow-right.png",
      "text": "Real estate",
      "iconSelection": "faHome"
    }, {
      "icon": "/img/arrow.png",
      "text": "Economy",
      "iconSelection": "faBalanceScale"
    }, {
      "icon": "/img/cloud.png",
      "text": "HR",
      "iconSelection": "faUserFriends"
    }, {
      "text": "Administration",
      "icon": "/img/computer-vision.png",
      "iconSelection": "faBox"
    }, {
      "icon": "/img/cost.png",
      "text": "Customer service",
      "iconSelection": "faComments"
    }, {
      "text": "IT",
      "icon": "/img/creative.png",
      "iconSelection": "faDatabase"
    }, {
      "text": "Others",
      "icon": "/img/hand.png",
      "iconSelection": "faLightbulb"
    }, {
      "icon": "/img/fb.png",
      "text": "Dont know",
      "iconSelection": "faQuestion"
    }],
    "step": 3
  },
  "priorities": {
    "title": "What are your priorities?",
    "mascot": "/img/happy1.png",
    "categories": [{
      "text": "Save time",
      "iconSelection": "faUserFriends"
    }, {
      "text": "Lower costs",
      "iconSelection": "faDatabase"
    }, {
      "text": "Minimize person dependency",
      "iconSelection": "faQuestion"
    }, {
      "text": "Increase quality",
      "iconSelection": "faHome"
    }, {
      "text": "Others",
      "iconSelection": "faLightbulb"
    }],
    "step": 4
  },
  "timeSpent": {
    "title": "How much time per week do you spend on the process?",
    "step": 5,
    "mascot": "/img/happy1.png",
    "categories": [{
      "text": "1-10 hours"
    }, {
      "text": "10-40 hours"
    }, {
      "text": "40+ hours"
    }, {
      "text": "Dont know"
    }]
  },
  "demo": {
    "title": "Your digital assistant is underway",
    "text": "We will get in touch with you shortly with a proposal of how we can help you. You may also book a time that suits you right away using the calendar below.",
    "demourl": "https://calendly.com/zimply/demo-with-zimply"
  }
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      